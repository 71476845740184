<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="800">
        <v-card v-if="dialog" min-height="400px">
            <v-card-title>
                <span class="headline">Serviços</span>
            </v-card-title>
            <v-card-text class="px-0">
                <v-list  dense v-if="servicos.length > 0">
                    <template v-for="(servico, index) in servicos">
                        <template v-if="servico.emissaoStatus !== 2">
                                <v-list-item @click="viewServico(servico, index)">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-regular">{{totalFiscalizacoes(servico.fiscalizacoes)}} para Agencia {{servico.agencia.codigo}} de {{servico.agencia.cidade.nome}}/{{servico.agencia.cidade.estado.sigla}}</v-list-item-title>
                                    <template>
                                        <v-list-item-subtitle class="font-weight-light">
                                            Distância excedida: <b>{{servico.distanciaExcedida}} Km ({{servico.valorTotalDistanciaExcedida | formatNumber}})</b>
                                        </v-list-item-subtitle>
                                    </template>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-subtitle class="font-weight-regular">
                                        {{servico.valorTotalServico | formatNumber}}
                                    </v-list-item-subtitle>
                                    <div style="height: 22px">
                                        <v-chip v-if="servico.notaFiscal" x-small label color="green" text-color="white" class="font-weight-bold py-2 px-2">
                                            ENVIADO
                                        </v-chip>
                                        <v-chip v-else x-small label color="red" text-color="white" class="font-weight-bold py-2 px-2">
                                            FALHA AO ENVIAR
                                        </v-chip>
                                    </div>

                                </v-list-item-action>
                                    <v-list-item-action>
                                        <v-menu offset-y left  >
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon :disabled="servico.erroEmissaoNotaFiscals.length === 0" @mousedown.stop @touchstart.native.stop  >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="">
                                                    <v-list-item-title>Corrigir</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>

                                    </v-list-item-action>

                            </v-list-item>
                            <v-divider ></v-divider>
                        </template>

                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>
                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="continuarProcesso" >
                            Emitir novamente e continuar
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <servico-dialog ref="servicoDialog" />
        <nota-fiscal-error-dialog ref="notaFiscalErrorDialog" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import NotaFiscalErrorDialog from "@/views/servico/fiscalizacao/servico/NotaFiscalErrorDialog";
import ServicoDialog from "@/views/servico/fiscalizacao/servico/ServicoDialog";

export default {
    name: "CobrarServicosResultadoDialog",
    components: {ServicoDialog, NotaFiscalErrorDialog},
    data(){
        return{
            dialog: false,
            loading: false,
            notificacaoId: null,
            loteId: null,
            servicos: [],
        }
    },
    methods:{
        openDialog(loteId, notificacaoId) {
            this.loteId = loteId;
            this.notificacaoId = notificacaoId;
            this.dialog = true;
            this.getServicosPorLote(loteId)
        },
        closeDialog() {
            this.dialog = false;
        },
        getServicosPorLote(loteId) {
            this.loading = true;
            this.$apollo.query({
                query: gql`query($loteId:Int!){
                    servicos:servicosPorLote(lote_id:$loteId, page: 0, offset: 1000, comNotasEmitidas: true){
                        itens{
                            id
                            distanciaExcedida
                            valorTotalDistanciaExcedida
                            valorTotalServico
                            notaFiscal{
                                id
                                dataEmissao
                                numero
                                valorServicos
                            }
                            erroEmissaoNotaFiscals{
                                id
                                codigo
                                mensagem
                                motivoSolucao
                            }
                            agencia{
                                id
                                nome
                                codigo
                                endereco
                                numero
                                bairro
                                cnpj
                                cep
                                cidade{
                                    nome
                                    codigoIbge
                                    estado{
                                        sigla
                                    }
                                }
                            }
                            fiscalizacoes{
                                id
                                distanciaPercorrida
                                financiamento{
                                    mutuario
                                    valor
                                }
                            }
                        }
                    }
                }`,
                variables:{
                    loteId,
                }
            }).then((result) => {
                this.servicos = result.data.servicos.itens;
                this.loading = false;
            }).finally(()=>{
                this.loading = false;
            });
        },
        totalFiscalizacoes(fiscalizacoes) {
            const total = fiscalizacoes.length;
            let label = ' fiscalização';
            if(total > 1){
                label = ' fiscalizações';
            }
            return total + label;
        },
        viewServico(servico, index){
            if(servico.erroEmissaoNotaFiscals.length > 0){
                this.$refs.notaFiscalErrorDialog.openDialog(servico.erroEmissaoNotaFiscals);
                return;
            }
            this.$refs.servicoDialog.openDialog(servico, index, true, true);
        },
        continuarProcesso(){
            this.closeDialog();
            this.apagarNotificacao(this.notificacaoId)
            this.emitirLote(this.loteId)
        },
        emitirLote(loteId){
            this.$apollo.mutate({
                mutation: gql`mutation ($loteId: Int!){
                        iniciaTarefaEmiteNotasFiscais(loteId: $loteId){
                            id
                        }
                    }`,
                variables: {
                    loteId,
                },
            });
        },
        apagarNotificacao(id){
            this.$apollo.mutate({
                mutation: gql`mutation ($id: Int!){
                        deleteNotificacao(id: $id)
                    }`,
                variables: {
                    id,
                },
            }).then((result)=>{
                if(result.data.deleteNotificacao){
                    this.$root.$emit("getTotalNotificacoes");
                }
            })
        },

    }
}
</script>

<style scoped>

</style>