<template>
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
        <v-card >
            <v-card-title>
                <span class="headline">Alterar senha</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form1" v-model="form1Valid" lazy-validation>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="oldPassword"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="show1 ? 'text' : 'password'"
                                        label="Senha atual" placeholder=" " tabindex="1"
                                        @click:append="show1 = !show1"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="newPassword"
                                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="show2 ? 'text' : 'password'"
                                        label="Nova senha" placeholder=" " tabindex="2"
                                        hint="No mínimo 8 caracteres"
                                        @click:append="show2 = !show2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="newPasswordConfirm"
                                        :rules="[rules.required, rules.match]"
                                        type="password" placeholder=" " tabindex="3"
                                        label="Confirmar senha"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
        <v-card-actions>
            <div class="pa-3" style="width: 100%">
                <v-btn text @click="closeDialog">Cancelar</v-btn>

                <div class="float-right">
                    <v-btn color="primary" text @click="save">Salvar</v-btn>
                </div>

            </div>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {UsuarioQueries} from '../../../queries/UsuarioQueries';
    export default {
        name: 'ChangePasswordDialog',
        data() {
            return{
                show1: false,
                show2: false,
                dialog: false,
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
                form1Valid: true,
                rules: {
                    required: (value) => !!value || 'Campo Obrigatório.',
                    min: (v) => v && v.length >= 8 || 'Mínimo 8 caracteres',
                    match: (v) => v === this.newPassword || 'Senhas não coincidem',
                },
            };
        },
        methods: {
            openDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
                this.$refs.form1.reset();
            },
            save() {
                if (this.$refs.form1.validate()) {
                    this.$apollo.mutate({
                        mutation: UsuarioQueries.updatePassword,
                        variables: {
                            oldPassword: this.oldPassword,
                            newPassword: this.newPassword,
                        },
                    }).then((result) => {
                        this.closeDialog();
                        this.$notify({text: 'Senha atualizada com sucesso!', type: 'success'});
                    }).catch((error) => {
                        this.$dialog.error({
                            text: 'Senha atual incorreta. Tente novamente!',
                            title: 'Erro',
                            actions: {
                                yes: {
                                    text: 'Ok',
                                },
                            },
                        });
                    });
                }
            },
        },
    };
</script>

<style scoped>

</style>