<template>
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
        <v-card v-if="usuario" >
            <v-card-title>
                <span class="headline">Editar</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form1" v-model="form1Valid" lazy-validation>
                        <v-row class="" >
                            <v-col sm cols="12">
                                <v-text-field v-model="usuario.nome" label="Nome"
                                              placeholder=" " :rules="requiredRules"
                                              required></v-text-field>
                            </v-col>
                            <v-col sm cols="12" >
                                <v-text-field v-model="usuario.sobrenome" label="Sobrenome"
                                              placeholder=" "></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {UsuarioQueries} from '../../../queries/UsuarioQueries';
    export default {
        name: 'EditCurrentUsuarioDialog',
        data() {
            return {
                dialog: false,
                usuario: null,
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
            };
        },
        methods: {
            openDialog(usuario) {
                this.dialog = true;
                this.usuario = this._.cloneDeep(usuario);
            },
            closeDialog() {
                this.dialog = false;
                this.$refs.form1.resetValidation();

            },
            save() {
                if (this.$refs.form1.validate()) {
                    this.$apollo.mutate({
                        mutation: UsuarioQueries.update,
                        variables: {
                            id: this.usuario.id,
                            usuario: this.parseRequest(this.usuario),
                        },
                    }).then((result) => {
                        this.$emit('updated', Object.assign({}, result.data.updateUsuario));
                        this.closeDialog();
                    });
                }
            },
            parseRequest(usuario) {
                const usuarioParsed = this._.cloneDeep(usuario);

                delete usuarioParsed.id;
                delete usuarioParsed.email;
                delete usuarioParsed.admin;
                delete usuarioParsed.imagem;
                delete usuarioParsed.active;
                delete usuarioParsed.resetPassword;
                delete usuarioParsed.createdAt;
                delete usuarioParsed.updatedAt;

                return usuarioParsed;
            },
        },
    };
</script>

<style scoped>

</style>