<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="800">
        <v-card v-if="dialog" min-height="400px">
            <v-card-title>
                <span class="headline">Fiscalizações</span>
            </v-card-title>
            <v-card-text class="px-0">
                <v-list two-line v-if="fiscalizacoes.length > 0">
                    <template v-for="(fiscalizacao) in fiscalizacoes">
                        <v-list-item :key="fiscalizacao.id"  >
                            <v-list-item-content>
                                <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <div>
                                        <v-chip v-if="fiscalizacao.envioStatus === 2" x-small label color="green" text-color="white" class="mr-1 font-weight-bold px-2">
                                            ENVIADO
                                        </v-chip>
                                        <v-chip v-if="fiscalizacao.envioStatus === 3" x-small label color="orange lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                            NÃO ENCONTRADO
                                        </v-chip>
                                        <v-chip v-if="fiscalizacao.envioStatus === 4" x-small label color="red" text-color="white" class="mr-1 font-weight-bold px-2">
                                            ERRO
                                        </v-chip>
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>N. Sol.: <b>{{fiscalizacao.numeroSolicitacao}}</b> Op.: <b>{{fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : '')}}</b></v-list-item-action-text>
                                <v-list-item-action-text>Data Fiscalização: <b>{{new Date(fiscalizacao.dataFiscalizacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn icon :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" target="_blank">
                                    <v-icon color="grey lighten-1">mdi-share</v-icon>
                                </v-btn>
                            </v-list-item-action>

                        </v-list-item>

                        <v-divider ></v-divider>
                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <!--                    <v-btn text @click="closeDialog">Cancelar</v-btn>-->
                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="closeDialog" >
                            Ok
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "EnviarLaudosResultadoDialog",
    data(){
        return{
            dialog: false,
            loading: false,
            fiscalizacoes: [],
        }
    },
    methods: {
        openDialog(tarefaLogId) {
            this.dialog = true;
            this.getFiscalizacoesPorTarefaLog(tarefaLogId)
        },
        closeDialog() {
            this.dialog = false;
        },
        getFiscalizacoesPorTarefaLog(tarefaLogId) {
            this.loading = true;
            this.$apollo.query({
                query: gql`query($tarefaLogId:Int!){
                    resultado:resutadoTarefaEnviaLaudosBanco(tarefaLogId:$tarefaLogId){
                         fiscalizacoesNaoEncontradas{
                            id
                            numeroSolicitacao
                            dataFiscalizacao
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                cedula
                                operacao
                                operacaoDigito
                            }
                        }
                        fiscalizacoesEnviadas{
                            id
                            numeroSolicitacao
                            dataFiscalizacao
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                cedula
                                operacao
                                operacaoDigito
                            }
                        }
                        fiscalizacoesNaoEnviadas{
                            id
                            numeroSolicitacao
                            dataFiscalizacao
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                cedula
                                operacao
                                operacaoDigito
                            }
                        }
                    }
                }`,
                variables:{
                    tarefaLogId,
                }
            }).then((result) => {
                //this.fiscalizacoes = result.data.fiscalizacoes;
                let resultado = result.data.resultado;
                //this.tarefa = result.data.tarefa;
                let fiscalizacoesEnviadas = resultado.fiscalizacoesEnviadas;
                fiscalizacoesEnviadas.forEach(fiscalizacao => fiscalizacao.envioStatus = 2);
                let fiscalizacoesNaoEncontradas = resultado.fiscalizacoesNaoEncontradas;
                fiscalizacoesNaoEncontradas.forEach(fiscalizacao => fiscalizacao.envioStatus = 3);
                let fiscalizacoesNaoEnviadas = resultado.fiscalizacoesNaoEnviadas;
                fiscalizacoesNaoEnviadas.forEach(fiscalizacao => fiscalizacao.envioStatus = 4);

                this.fiscalizacoes = fiscalizacoesNaoEnviadas.concat(fiscalizacoesNaoEncontradas).concat(fiscalizacoesEnviadas)
                this.loading = false;
            }).finally(()=>{
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>

</style>