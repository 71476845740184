<template>
    <div>
        <v-navigation-drawer :expand-on-hover="!$vuetify.breakpoint.mobile" v-model="drawerOpened" clipped app >
<!--            <v-list-item class="px-2" v-if="usuario" @click="openUsuarioInfoDialog(usuario)">
                <v-list-item-avatar color="blue darken-1">
                    <usuario-avatar :usuario="usuario" />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{usuario.nome}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>-->
            <v-list dense>
                <v-list-item-group v-model="item" color="primary" @change="goto">
                    <v-list-item value="dashboard" >
                        <v-list-item-icon><v-icon>mdi-monitor-dashboard</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Início</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item value="lembretes">
                        <v-list-item-icon><v-icon>mdi-reminder</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Lembretes
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-chip x-small color="red" dark class="ml-4 px-2"
                                    v-if="$store.state.lembrete.total > 0">
                                {{$store.state.lembrete.total}}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item value="emails" v-if="usuario.admin">
                        <v-list-item-icon><v-icon>mdi-email-multiple-outline</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Emails</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-group :value="false"  no-action prepend-icon="mdi-hammer-screwdriver">
                        <template v-slot:activator>
                            <v-list-item-title>Serviços</v-list-item-title>
                        </template>
                        <v-list-item value="fiscalizacoes">
                            <v-list-item-title>Fiscalizações</v-list-item-title>
                            <v-list-item-icon ><v-icon>mdi-clipboard-check-outline</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item value="projetos">
                            <v-list-item-title>Projetos</v-list-item-title>
                            <v-list-item-icon ><v-icon>mdi-file-document-outline</v-icon></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item value="financeiro" v-if="usuario.admin || usuario.id === 14">
                        <v-list-item-icon><v-icon>mdi-finance</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Financeiro</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

<!--                    <v-list-group :value="false"  no-action prepend-icon="mdi-finance" v-if="usuario.admin || usuario.id === 14">
                        <template v-slot:activator>
                            <v-list-item-title>Financeiro</v-list-item-title>
                        </template>
                        <v-list-item value="financeiro">
                            <v-list-item-title>Caixas</v-list-item-title>
                            <v-list-item-icon><v-icon>mdi-cash-register</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item value="conta">
                            <v-list-item-title>Conta</v-list-item-title>
                            <v-list-item-icon><v-icon>mdi-bank-transfer</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item value="notas_fiscais">
                            <v-list-item-title>Notas Fiscais</v-list-item-title>
                            <v-list-item-icon><v-icon>mdi-calculator</v-icon></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>-->

                    <v-list-group :value="false"  no-action prepend-icon="mdi-package-variant">
                        <template v-slot:activator>
                            <v-list-item-title>Recursos</v-list-item-title>
                        </template>
                        <v-list-item value="agencias">
                            <v-list-item-title>Agências</v-list-item-title>
                            <v-list-item-icon ><v-icon>mdi-home-city-outline</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item value="cidades">
                            <v-list-item-title>Cidades</v-list-item-title>
                            <v-list-item-icon ><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group :value="false"  no-action prepend-icon="mdi-cog-outline">
                        <template v-slot:activator>
                            <v-list-item-title>Sistema</v-list-item-title>
                        </template>
                        <v-list-item value="tarefas">
                            <v-list-item-title>Tarefas executadas</v-list-item-title>
                            <v-list-item-icon ><v-icon>mdi-alarm</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item value="usuarios" v-if="usuario.admin">
                            <v-list-item-title>Usuários</v-list-item-title>
                            <v-list-item-icon ><v-icon>mdi-account-multiple</v-icon></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="showNotificacoes"
                             :width="$vuetify.breakpoint.smAndDown ? 250 : 350"
                             app temporary right class="pa-2">
            <template v-for="(notificacao, index) in notificacoes">
                <v-alert  text dense
                          color="deep-orange" icon="mdi-alert-circle-outline" border="left" :key="index">
                    {{notificacao.titulo}}
                    <v-divider class="my-2 " style="border-color: #ffc4b1"></v-divider>
                    <v-btn v-if="notificacao.code === 'notas_fiscais_emissao_com_erro'" small @click="showCobrarServicosResultadoDialog(notificacao)">+ Detalhes</v-btn>
                    <v-btn v-if="notificacao.code === 'envio_laudo_com_erro'" small @click="showEnviarLaudosResultadoDialog(notificacao)">+ Detalhes</v-btn>
                </v-alert>
            </template>
        </v-navigation-drawer>

        <v-app-bar app light color="#81c784" clipped-left>
            <v-app-bar-nav-icon style="color: #6d4c41" @click.stop="drawerOpened = !drawerOpened"></v-app-bar-nav-icon>
            <v-toolbar-title v-if="!$vuetify.breakpoint.mobile" class="d-flex align-center" style="color: #6d4c41">
                <span>JF Assessoria Rural</span>
                <busca-rapida />
            </v-toolbar-title>
            <v-toolbar-title v-else style="color: #6d4c41" class="pl-0 pl-sm-5">
                <busca-rapida v-if="$route.path === '/admin/'" />
                <portal-target v-else name="title_portal">
                </portal-target>
            </v-toolbar-title>
            <v-spacer/>


            <v-menu rounded offset-y>
                <template v-slot:activator="{ attrs, on }">
                    <v-badge color="red" :value="totalNotificacoes > 0" :content="totalNotificacoes" overlap offset-x="16" offset-y="16">
                        <v-avatar class="ml-4" style="border: 3px solid #e2e2e2;" v-bind="attrs" v-on="on" >
                            <usuario-avatar :usuario="usuario" />
                        </v-avatar>
                    </v-badge>
                </template>

                <v-list>
                    <v-list-item @click="openUsuarioInfoDialog(usuario)">
                        <v-list-item-title class="font-weight-medium">{{usuario.nome + ' ' + usuario.sobrenome}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showNotificacoes = true">
                        <v-list-item-title>
                            Notificações
                        </v-list-item-title>
                        <v-list-item-action>
                            <v-chip x-small color="red" dark class="px-2" v-if="totalNotificacoes > 0">
                                {{totalNotificacoes}}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="showNotificacoes = true">
                        <v-list-item-title>Processos</v-list-item-title>
                        <v-list-item-action>
                            <v-chip x-small color="red" dark class="px-2" v-if="false">
                                {{0}}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>
<!--                    <v-list-item @click="emitiEmail">
                        <v-list-item-title>Emitir email</v-list-item-title>
                    </v-list-item>-->
                    <v-divider/>
                    <v-list-item @click="logout()">
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>


        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>

        <view-info-dialog ref="viewInfoDialog" @updatedUsuario="getUsuarioInfo" />
        <cobrar-servicos-resultado-dialog ref="cobrarServicosResultadoDialog" />
        <enviar-laudos-resultado-dialog ref="enviarLaudosResultadoDialog" />
        <envia-laudos-banco-relatorio-dialog ref="enviaLaudosBancoRelatorioDialog" />

        <v-footer color="#81c784" light app v-if="tarefasEmExecucao.length > 0" >
            <div class="d-flex align-center mb-2 mt-1">
                <div class="mr-2" v-if="tarefasEmExecucao.length > 1">
                    <v-btn fab color="white" @click="prevTarefa"
                           elevation="1" height="20" width="20">
                        <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn class="ml-1" fab color="white"  @click="nextTarefa"
                           elevation="1" height="20" width="20">
                        <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
                <span class="caption mt-1">
                    Tarefa em execução {{tarefaEmExecucaoExibida + 1}}/{{tarefasEmExecucao.length}}: <b>{{tarefasEmExecucao[tarefaEmExecucaoExibida].nome}}</b>
                </span>

            </div>
            <v-progress-linear
                :indeterminate="tarefasEmExecucao[tarefaEmExecucaoExibida].total === null"
                color="black" class="mb-2" stream :buffer-value="getTarefaBufferPercent(tarefasEmExecucao[tarefaEmExecucaoExibida])"
                :value="getTarefaProgressPercent(tarefasEmExecucao[tarefaEmExecucaoExibida])" ></v-progress-linear>
        </v-footer>
    </div>
</template>

<script>
import {Usuario} from '../models/Usuario';
    // @ts-ignore
    import ViewInfoDialog from '../views/sistema/usuario/ViewInfoDialog';
    // @ts-ignore
    import UsuarioAvatar from '../components/UsuarioAvatar';
    import {AuthService} from "../service/AuthService";
    // @ts-ignore
    import BuscaRapida from "../components/BuscaRapida";
    import gql from "graphql-tag";
import {UsuarioQueries} from "@/queries/UsuarioQueries";
import localforage from "localforage";
import NotaFiscalErrorDialog from "@/views/servico/fiscalizacao/servico/NotaFiscalErrorDialog";
import CobrarServicosResultadoDialog from "@/views/financeiro/CobrarServicosResultadoDialog";
import EnviarLaudosResultadoDialog from "@/views/financeiro/EnviarLaudosResultadoDialog";
import EnviaLaudosBancoRelatorioDialog from "@/views/sistema/EnviaLaudosBancoRelatorioDialog";

    export default {
        name: 'Admin',
        components: {
            EnviaLaudosBancoRelatorioDialog,
            EnviarLaudosResultadoDialog,
            CobrarServicosResultadoDialog, NotaFiscalErrorDialog, BuscaRapida, UsuarioAvatar, ViewInfoDialog},
        data() {
            return {
                drawerOpened: this.closeDrawerOn(this.$route.name),
                usuario: new Usuario(),
                item: 0,
                //showFooter: true,
                tarefasEmExecucao: [],
                tarefaEmExecucaoExibida: 0,
                totalNotificacoes: 0,
                notificacoes: [],
                showNotificacoes: false,
            };
        },
        watch: {
            $route(to, from) {
                //this.drawerOpened = this.closeDrawerOn(to.name);
            },
            showNotificacoes(value){
                if(value){
                    if(this.usuario){
                        this.getNotificacoes(this.usuario.id)
                    }
                }
            }
        },
        computed:{
            /*grupoFiscalizacoesItens(){
                return this.item === 'fiscalizacoes' || this.item === 'todas_fiscalizacoes';
            },
            grupoSistemaItens(){
                //return this.item === 'tarefas-executadas' || this.item === 'todas_fiscalizacoes';
                return this.item === 'tarefas-executadas';
            }*/
        },
        apollo:{
            $subscribe:{
                tarefaAtualizada:{
                    query: gql`subscription {
                        tarefa:tarefaLogPublisher{
                            id
                            nome
                            inicio
                            fim
                            log
                            tipo
                            temResultado
                            disparadoPor{
                                id
                                nome
                            }
                            concluidoComSucesso
                        }
                    }`,
                    result ({ data }) {
                        let tarefa = data.tarefa;
                        console.log("tarefa", tarefa)
                        let tarefaEncontradaIndex = this.tarefasEmExecucao.findIndex(t => t.id === tarefa.id);
                        if(tarefaEncontradaIndex > -1){
                            if(tarefa.fim){
                                this.tarefasEmExecucao.splice(tarefaEncontradaIndex, 1)
                            }else{
                                console.log("dar um merge na tarefa")
                            }
                        }else{
                            if(!tarefa.fim) {
                                if(tarefa.tipo === 'baixa-extrato-bancario' && !(this.usuario.admin || this.usuario.id === 14)){
                                    return
                                }
                                tarefa.total = null;
                                tarefa.current = null;
                                this.tarefasEmExecucao.push(tarefa)
                            }
                        }

                        if(tarefa.fim){
                            if(!tarefa.concluidoComSucesso){
                                this.getTotalNotificacoes();
                                this.$notify({group: "tarefa-log-error", data: tarefa, duration: 3000});
                            }

                            if(tarefa.concluidoComSucesso && tarefa.temResultado){
                                this.$notify({group: "tarefa-relatorio", data: tarefa, duration: 3000});
                            }
                        }
                    },
                },
                tarefaEnvioLaudosBancoProgresso:{
                    query: gql`subscription {
                        tarefa:tarefaEnvioLaudosBancoProgressPublisher{
                            tarefaLog{
                                id
                                nome
                                fim
                            }
                            total
                            current
                        }
                    }`,
                    result ({ data }) {
                        this.resolveTarefaProgress(data.tarefa)
                    },
                },
                tarefaImportaArquivosProgresso:{
                    query: gql`subscription {
                        tarefa:tarefaImportaArquivosProgressPublisher{
                            tarefaLog{
                                id
                                nome
                                fim
                            }
                            total
                            current
                        }
                    }`,
                    result ({ data }) {
                        this.resolveTarefaProgress(data.tarefa)
                    },
                },
                tarefaProcessaCoordenadasProgresso:{
                    query: gql`subscription {
                        tarefa:tarefaProcessaCoordenadasProgressPublisher{
                            tarefaLog{
                                id
                                nome
                                fim
                            }
                            total
                            current
                        }
                    }`,
                    result ({ data }) {
                        this.resolveTarefaProgress(data.tarefa)
                    },
                },
                tarefaEmiteNotasFiscaisProgresso:{
                    query: gql`subscription {
                        tarefa:tarefaEmiteNotasFiscaisProgressPublisher{
                            tarefaLog{
                                id
                                nome
                                fim
                            }
                            total
                            current
                        }
                    }`,
                    result ({ data }) {
                        this.resolveTarefaProgress(data.tarefa)
                    },
                },
                notificacaoAtualizada:{
                    query: gql`subscription {
                        notificacao:notificacaoPublisher{
                            id
                            code
                            titulo
                            descricao
                            body
                        }
                    }`,
                    result ({ data }) {
                        let notificacao = data.notificacao;
                        this.getTotalNotificacoes();
                        switch (notificacao.code){
                            case "notas_fiscais_emissao_com_erro":
                                this.$notify({
                                    group: "notifcacao-error",
                                    id: new Date().getTime(),
                                    text: notificacao.titulo,
                                    duration: 3000});
                                break;
                        }

                    },
                }
            }
        },
        mounted() {
            this.item = this.$route.name;
            this.getUsuarioInfo();
            this.$store.dispatch("lembrete/getTotalLembretes");
            this.$root.$on('openNotificationArea', this.openNotificationsArea);
            this.$root.$on('getTotalNotificacoes', this.getTotalNotificacoes);
            /*this.$apollo.addSmartSubscription('notification',{
                query: gql`subscription {
                    notification{
                        data
                        role
                        type
                    }
                }`,
                result({data}){
                    console.log("resultSubscription", data)
                }
            });*/
            /*const observer = this.$apollo.subscribe({
                query: gql`subscription {
                    notification
                }`,
            })*/


        },
        destroyed() {
            this.$root.$off('openNotificationArea', this.openNotificationsArea);
            this.$root.$off('getTotalNotificacoes', this.getTotalNotificacoes);
        },
        methods: {
            /*emitiEmail(){
                this.$apollo.mutate({
                    mutation: gql`mutation {
                        tarefa:iniciaTarefaEnviarEmailSolicitacaoPagamento{
                            id
                        }
                    }`,
                }).then((result) => {
                    console.log(result.data)
                })
            },*/
            closeDrawerOn(pageName){
                switch (pageName) {
                    case 'fiscalizacao_view':
                        return false;
                    case 'emails':
                        return false;
                }
            },
            goto(value){
                console.log("goto", value)
                let params = {};
                if(typeof value === "number"){
                    return;
                }
                if(value === 'emails'){
                    params = {
                        local: 'entrada'
                    }
                }
                if(value){
                    this.$router.push({name: value, params})
                }
            },
            getUsuarioInfo() {
                AuthService.getCurrentUser().then((usuario) => {
                    if (usuario) {
                        this.usuario = usuario;
                        this.getTotalNotificacoes();
                    } else {
                        this.$router.replace({path: '/login', query: { returnUrl: this.$route.path }});
                    }
                });
            },
            openNotificationsArea(){
                this.showNotificacoes = true;
            },
            getTotalNotificacoes(){
                this.$apollo.query({
                    query: gql`query($id:Int!) {
                        total:totalNotificacoesNaoLidasByUsuario(id: $id)
                    }`,
                    variables:{
                        id: this.usuario.id
                    }
                }).then((result) => {
                    this.totalNotificacoes = result.data.total;
                });
            },
            getNotificacoes(id){
                this.$apollo.query({
                    query: gql`query($id:Int!) {
                        notificacoes:notificacoesNaoLidasByUsuario(id: $id){
                            id
                            code
                            titulo
                            descricao
                            readedAt
                            createdAt
                            body
                            usuario{
                                id
                            }
                        }
                    }`,
                    variables:{
                        id
                    }
                }).then((result) => {
                    this.notificacoes = result.data.notificacoes;
                });
            },
            showCobrarServicosResultadoDialog(notificacao){
                let loteId = JSON.parse(notificacao.body).id
                this.$refs.cobrarServicosResultadoDialog.openDialog(loteId, notificacao.id);
            },
            showEnviarLaudosResultadoDialog(notificacao){
                let tarefaLogId = JSON.parse(notificacao.body).id
                this.$refs.enviarLaudosResultadoDialog.openDialog(tarefaLogId)
                //this.$refs.enviaLaudosBancoRelatorioDialog.openDialog(tarefaLogId)
            },
            openUsuarioInfoDialog(usuario) {
                this.$refs.viewInfoDialog.openDialog(usuario);
            },
            makeAvatarText(usuario) {
                return usuario.nome[0].toUpperCase() + (usuario.sobrenome ? usuario.sobrenome[0].toUpperCase() : '');
            },
            prevTarefa(){
                if(this.tarefaEmExecucaoExibida === 0){
                    this.tarefaEmExecucaoExibida = this.tarefasEmExecucao.length - 1;
                    return
                }
                this.tarefaEmExecucaoExibida--;
            },
            nextTarefa(){
                if((this.tarefaEmExecucaoExibida + 1) === this.tarefasEmExecucao.length){
                    this.tarefaEmExecucaoExibida = 0;
                    return
                }
                this.tarefaEmExecucaoExibida++;
            },
            async resolveTarefaProgress(progresso){
                let tarefaEncontradaIndex = this.tarefasEmExecucao.findIndex(t => t.id === progresso.tarefaLog.id);
                if(tarefaEncontradaIndex > -1){
                    let tarefaEncontrada = this.tarefasEmExecucao[tarefaEncontradaIndex];
                    tarefaEncontrada.total = progresso.total
                    tarefaEncontrada.current = progresso.current
                }else{
                    if(progresso.current !== progresso.total){
                        progresso.tarefaLog.total = progresso.total;
                        progresso.tarefaLog.current = progresso.current;
                        this.tarefasEmExecucao.push(progresso.tarefaLog)
                    }
                }
            },
            getTarefaProgressPercent(tarefa){
                return Math.floor((tarefa.current - 1) / tarefa.total*100)
            },
            getTarefaBufferPercent(tarefa){
                return Math.floor(tarefa.current / tarefa.total*100)
            },
            logout() {
                this.$dialog.confirm({
                    text: 'Deseja realmente sair?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        AuthService.logout(this.$router);
                    }
                });
            },
        },
    };
</script>
<style>
    span.highlight {
        background-color: yellow;
    }
</style>

<style scoped>

</style>
