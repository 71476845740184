<template>
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
        <v-card v-if="usuario" min-height="280px">
            <v-card-text class="pb-0">
                <v-row no-gutters class="">
                    <v-col class="shrink col-12 col-sm-auto">
                        <div style="position: relative; width: 140px; margin: auto">
                            <v-avatar class="mt-4" size="140px" color="blue darken-1">
                                <usuario-avatar :usuario="usuario" text-class="display-3" />
                            </v-avatar>
                            <v-btn fab small dark color="secondary" elevation="2"
                                   :loading="imageUploading"
                                   :disabled="imageUploading"
                                   @click="$refs.inputUpload.click()"
                                   style="position: absolute; bottom: 0px;right: 5px;">
                                <v-icon class="title">mdi-camera</v-icon>
                            </v-btn>
                            <input v-show="false" ref="inputUpload" type="file"
                                   accept="image/png, image/jpeg, image/bmp"
                                   @change="uploadImage" >
                        </div>
                    </v-col>
                    <v-col class="pt-6 ml-8 grow">
                        <v-row no-gutters>
                            <v-col>
                                <p class="title mb-0">{{usuario.nome}} {{usuario.sobrenome}}</p>
                                <p class="body-2">{{usuario.email}}</p>
                            </v-col>
                            <v-col class="shrink">
                                <v-menu bottom left >
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon small>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="editUsuarioDialog(usuario)">
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="changeUsuarioPassDialog()">
                                            <v-list-item-title>Alterar Senha</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-btn v-if="!hasGmailCredential"
                               class="mt-8 white--text" color="#D44638"
                               @click="adicionarContaGmail"
                                :disabled="awaitingGmailAuth"
                                :loading="awaitingGmailAuth">
                            <v-icon left color="">mdi-gmail</v-icon>
                            Vincular Conta Gmail
                            <template v-slot:loader>
                                <span>Aguardando...</span>
                            </template>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <div class="float-right">
                        <v-btn color="primary" text @click="closeDialog">Ok</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
        <edit-current-usuario-dialog ref="editUsuarioDialog" @updated="onUsuarioEdited" />
        <change-password-dialog ref="changePasswordDialog" />
    </v-dialog>
</template>

<script>
    // @ts-ignore
    import EditCurrentUsuarioDialog from './EditCurrentUsuarioDialog';
    import localforage from 'localforage';
    // @ts-ignore
    import ChangePasswordDialog from './ChangePasswordDialog';
    import {UsuarioQueries} from '../../../queries/UsuarioQueries';
    // @ts-ignore
    import UsuarioAvatar from '../../../components/UsuarioAvatar';
    import router from "../../../router";
    import Vue from "vue";
    import {AuthService} from "../../../service/AuthService";
    export default {
        name: 'ViewInfoDialog',
        components: {
            UsuarioAvatar,
            ChangePasswordDialog,
            EditCurrentUsuarioDialog,
        },
        data() {
            return {
                dialog: false,
                usuario: null,
                imageUploading: false,
                hasGmailCredential: true,
                awaitingGmailAuth: false,
                awaitingGmailAuthInterval: null,
            };
        },
        methods: {
            openDialog(usuario) {
                this.dialog = true;
                this.usuario = usuario;
                this.checkGmailCredential();
            },
            closeDialog() {
                this.dialog = false;
                this.awaitingGmailAuthInterval = null;
                this.awaitingGmailAuth = false;
                this.hasGmailCredential = true;
            },
            editUsuarioDialog(usuario) {
                this.$refs.editUsuarioDialog.openDialog(usuario);
            },
            onUsuarioEdited(usuario) {
                this.usuario = usuario;
                localforage.setItem('usuario', usuario).then(() => {
                    this.$emit('updatedUsuario', usuario);
                });
            },
            changeUsuarioPassDialog() {
                this.$refs.changePasswordDialog.openDialog();
            },
            uploadImage(e){
                this.imageUploading = true;
                let file = e.target.files[0];

                if(file.size > 5000000){
                    this.$dialog.warning({text: 'Imagem não pode ter tamanho superior a 5 MB!', title: 'Atenção'});
                    return;
                }

                const formData = new FormData();
                formData.append('file', file);
                this.$axios.post('/usuarios/uploadImage', formData, {
                    headers: {
                        // @ts-ignore
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    },
                }).then(() => {
                    this.imageUploading = false;
                    this.$notify({text: 'Imagem enviada com sucesso!', type: 'success'});
                    this.getUsuarioInfo();
                }).catch((error) => {
                    this.imageUploading = false;
                    this.$dialog.error({text: 'Falha ao enviar arquivo!', title: 'Erro'});
                });
            },
            getUsuarioInfo() {
                this.$root.$emit('profilePictureUpdated');
                this.$apollo.query({
                    query: UsuarioQueries.getUsuarioInfo,
                }).then((result) => {
                    this.usuario = result.data.usuarioInfo;
                    localforage.setItem('usuario', this.usuario);
                });
            },
            adicionarContaGmail(){
                this.awaitingGmailAuth = true;
                this.$axios.get("/google/authorize").then((result) => {
                    window.open(result.data, "_blank");
                    this.awaitingGmailAuthInterval = setInterval(this.checkGmailCredential, 2000)
                })
            },
            checkGmailCredential(){
                this.$axios.get("/google/gmail/me")
                    .then((result) => {
                        this.hasGmailCredential = true;
                        clearInterval(this.awaitingGmailAuthInterval)
                    }).catch((result) => {
                        this.hasGmailCredential = false;
                    }).finally(()=>{

                    })
            },

        },
    };
</script>

<style scoped>

</style>