<template>
    <v-menu nudge-left="10" min-width="620" max-width="620" content-class="mt-n2"
            v-model="fastSearchOpened" :close-on-content-click="false"  >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field class="ml-0 ml-sm-4" v-on="on" style="width: 600px;" placeholder="Busca rápida"
                          hide-details solo flat background-color="#76b37a" readonly />
        </template>
        <v-card class="">
            <v-text-field class="px-2 pt-2" autofocus autocomplete="off" clearable
                          hide-details flat solo background-color="grey lighten-4"
                          v-model.trim="fastSearchValue" :loading="isFastSearchLoading" />
            <div v-if="fastSearchValue === null || fastSearchValue.trim() === ''" class=" pa-2">
                <span class="caption">Busque por fiscalizações, projetos, agências ou cidades</span>
            </div>

            <v-list dense class="py-0" style="max-height: calc(95vh - 56px); overflow: auto;"
                    v-else-if="fastSearchValue.length > 0 && (fastSearchItens.projetos.length > 0 || fastSearchItens.fiscalizacoes.length > 0 || fastSearchItens.agencias.length > 0 || fastSearchItens.cidades.length > 0)" >
                <template v-if="fastSearchItens.fiscalizacoes.length > 0">
                    <v-subheader>Fiscalizações</v-subheader>
                    <template v-for="(fiscalizacao, i) in fastSearchItens.fiscalizacoes">
                        <v-list-item :key="'a'+i" :class="getFinalizadoClass(fiscalizacao)" :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" >
                            <v-list-item-avatar size="38" >
                                <v-icon dense class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="highlight(fiscalizacao.financiamento.mutuario, fastSearchValue)"></v-list-item-title>

                                <v-list-item-subtitle  v-html="'N. Solicitação: ' + highlight(fiscalizacao.numeroSolicitacao, fastSearchValue) + '&emsp;Operação: ' + highlight(fiscalizacao.financiamento.operacao+'-'+fiscalizacao.financiamento.operacaoDigito, fastSearchValue)" >
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action class="my-0 py-2">
                                <v-list-item-action-text >Data Cedula: <b>{{new Date(fiscalizacao.financiamento.dataCedula) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                <v-list-item-action-text >Data Limite: <b>{{new Date(fiscalizacao.dataLimite) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                            </v-list-item-action>

                            <div v-if="fiscalizacao.dataCancelamento" class="d-flex justify-center align-center"
                                 style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: repeating-linear-gradient(45deg,transparent,transparent 30px,rgba(255,0,0,0.1) 1px, rgba(255,0,0,0.1) 60px
  )">
                                <span class="text-h5 font-weight-black">CANCELADO</span>
                            </div>

                        </v-list-item>
                    </template>
                    <v-list-item dense class="" v-if="fastSearchItens.totalFiscalizacoes > 3">
                        <v-list-item-content class="caption">
                            <span>Mais {{fastSearchItens.totalFiscalizacoes - 3}} resultados</span>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <template v-if="fastSearchItens.projetos.length > 0">
                    <v-subheader>Projetos</v-subheader>
                    <template v-for="(projeto, i) in fastSearchItens.projetos">
                        <v-list-item :key="'b'+i" :to="{ name: 'projeto_view', params: { id: parseInt(projeto.id) }}" >
                            <v-list-item-content>
                                <v-list-item-title v-html="highlight(projeto.cliente, fastSearchValue)"></v-list-item-title>

<!--                                <v-list-item-subtitle  v-html="'N. Solicitação: ' + highlight(fiscalizacao.numeroSolicitacao, fastSearchValue) + '&emsp;Operação: ' + highlight(fiscalizacao.financiamento.operacao+'-'+fiscalizacao.financiamento.operacaoDigito, fastSearchValue)" >
                                </v-list-item-subtitle>-->
                                <div>
                                    <v-chip x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                        <span>{{projeto.tipo.nome}}</span>
                                    </v-chip>
                                </div>
                            </v-list-item-content>

                            <v-list-item-action class="my-0 py-2">
                                <v-list-item-action-text >Data Solicitação: <b>{{new Date(projeto.dataSolicitacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                <v-list-item-action-text v-if="projeto.dataEnvio">Data Envio: <b>{{new Date(projeto.dataEnvio) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                            </v-list-item-action>

                        </v-list-item>
                    </template>
                    <v-list-item dense class="" v-if="fastSearchItens.totalProjetos > 3">
                        <v-list-item-content class="caption">
                            <span>Mais {{fastSearchItens.totalProjetos - 3}} resultados</span>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <template v-if="fastSearchItens.agencias.length > 0">
                    <v-divider v-if="fastSearchItens.fiscalizacoes.length > 0"></v-divider>
                    <v-subheader>Agências</v-subheader>
                    <template v-for="(agencia, i) in fastSearchItens.agencias">
                        <v-list-item :key="'c'+i" :to="{ name: 'agencia_view', params: { id: parseInt(agencia.id) }}" >
                            <v-list-item-content>
                                <v-list-item-title v-html="highlight(agencia.codigo, fastSearchValue) + ' ' + highlight(agencia.nome, fastSearchValue)"></v-list-item-title>
                                <v-list-item-subtitle>
                                    <template v-if="agencia.totalFiscalizacoes > 0">
                                        <span class="caption">{{agencia.totalFiscalizacoes}} Fiscalizações</span>
                                        <span class="caption" v-if="agencia.totalFiscalizacoesPendentes"> ({{agencia.totalFiscalizacoesPendentes}} pendentes)</span>
                                    </template>
                                    <span v-else class="caption">Nenhuma fiscalização</span>
                                </v-list-item-subtitle>


                            </v-list-item-content>
                            <v-list-item-action>
                                <v-chip v-if="agencia.fechadaEm" x-small color="red" label dark >FECHADA</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                    </template>
                    <v-list-item dense class="" v-if="fastSearchItens.totalAgencias > 3">
                        <v-list-item-content class="caption">
                            <span>Mais {{fastSearchItens.totalAgencias - 3}} resultados</span>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <template v-if="fastSearchItens.cidades.length > 0">
                    <v-divider v-if="fastSearchItens.cidades.length > 0"></v-divider>
                    <v-subheader>Cidades</v-subheader>
                    <template v-for="(cidade, i) in fastSearchItens.cidades">
                        <v-list-item :key="'d'+i" :to="{ name: 'cidade_view', params: { id: parseInt(cidade.id) }}" >
                            <v-list-item-content>
                                <v-list-item-title v-html="highlight(cidade.nome, fastSearchValue) + ' - ' + highlight(cidade.estado.sigla, fastSearchValue)"></v-list-item-title>
                                <v-list-item-subtitle>
                                    <template v-if="cidade.totalAgencias > 0">
                                        <span class="caption">{{cidade.totalAgencias}} Agências</span>
                                    </template>
                                    <span v-else class="caption">Nenhuma agência</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </template>
                    <v-list-item dense class="" v-if="fastSearchItens.totalCidades > 3">
                        <v-list-item-content class="caption">
                            <span>Mais {{fastSearchItens.totalCidades - 3}} resultados</span>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <div v-else class=" pa-4 text-center">
                <span v-if="!isFastSearchLoading" class="body-1 ">Nenhum resultado encontrado</span>
            </div>

        </v-card>

    </v-menu>
</template>

<script>
import gql from "graphql-tag";
import {getFinalizadoClass, getIconByFiscalizacaoTipo} from "../assets/FiscaizacaoUtil";

export default {
    name: "BuscaRapida",
    watch: {
        fastSearchOpened(value){
            if(!value){
                this.fastSearchValue = null;
            }
        },
        $route(to, from) {
            this.fastSearchOpened = false;
            this.fastSearchValue = '';
        },
        fastSearchValue(value) {
            if (value !== null && value !== '') {
                this.fastSearchOpened = true;
                this.isFastSearchLoading = true;
                //debounce
                clearTimeout(this._timerId)
                this._timerId = setTimeout(() => {
                    this.fastSearch(value);
                }, 250)

            }else{
                this.fastSearchItens = {
                    totalFiscalizacoes: 0,
                    totalAgencias: 0,
                    totalCidades: 0,
                    fiscalizacoes: [],
                    agencias: [],
                    cidades: []
                };
            }
        },
    },
    data(){
        return{
            fastSearchQuery: null,
            fastSearchItens: {
                totalProjetos: 0,
                totalFiscalizacoes: 0,
                totalAgencias: 0,
                totalCidades: 0,
                projetos: [],
                fiscalizacoes: [],
                agencias: [],
                cidades: []
            },
            isFastSearchLoading: false,
            fastSearchValue: null,
            fastSearchOpened: false,
        }
    },
    methods: {
        fastSearch(value) {
            let filter = {
                searchCriteria: 'todos',
                searchValue: value,
                itens: []
            }

            this.$apollo.query({
                query: gql`query ($filter: SearchFilterInput!, $offset: Int!) {
                        projetos: searchProjetos(filter: $filter, offset: $offset){
                            totalCount
                            itens{
                                id
                                cliente
                                dataSolicitacao
                                dataEnvio
                                tipo{
                                    nome
                                }
                            }
                        }
                        fiscalizacoes: searchFiscalizacoes(filter: $filter, offset: $offset){
                            totalCount
                            itens{
                                id
                                numeroSolicitacao
                                dataLimite
                                status
                                regular
                                dataFiscalizacao
                                tipo{
                                    nome
                                }
                                financiamento{
                                    dataCedula
                                    operacao
                                    operacaoDigito
                                    mutuario
                                }
                                dataCancelamento
                            }
                        }
                        agencias: searchAgenciasComTotalFiscalizacoes(filter: $filter, offset: $offset){
                            totalCount
                            itens{
                                id
                                nome
                                codigo
                                totalFiscalizacoes
                                totalFiscalizacoesPendentes
                                fechadaEm
                            }
                        }
                        cidades: searchCidades(filter: $filter, offset: $offset){
                            totalCount
                            itens{
                                id
                                nome
                                estado{
                                    sigla
                                }
                                totalAgencias
                            }
                        }
                    }`,
                variables: {
                    filter: filter,
                    offset: 3
                },
            }).then((result) => {
                this.fastSearchItens.projetos = result.data.projetos.itens;
                this.fastSearchItens.totalProjetos = result.data.projetos.totalCount;
                this.fastSearchItens.fiscalizacoes = result.data.fiscalizacoes.itens;
                this.fastSearchItens.totalFiscalizacoes = result.data.fiscalizacoes.totalCount;
                this.fastSearchItens.agencias = result.data.agencias.itens;
                this.fastSearchItens.totalAgencias = result.data.agencias.totalCount;
                this.fastSearchItens.cidades = result.data.cidades.itens;
                this.fastSearchItens.totalCidades = result.data.cidades.totalCount;
            }).finally(() => {
                this.isFastSearchLoading = false;
            });
        },
        highlight: function(words, query){
            if(query === ''){
                return words;
            }
            return words.toString().replace(new RegExp(query, "gi"), matchedTxt => {
                return '<span class="highlight">' + matchedTxt + '</span>';
            });
        },
        getItemIcon(tipo){
            return getIconByFiscalizacaoTipo(tipo);
        },
        getFinalizadoClass(fiscalizacao){
            return getFinalizadoClass(fiscalizacao);
        },
    }
}
</script>

<style scoped>
    .fast-search-menu{
        top: 0px !important;
    }
    .v-list-item-finalizado .v-list-item__title,
    .v-list-item-finalizado .v-list-item__subtitle,
    .v-list-item-finalizado .v-list-item__action-text{
        opacity: 0.7;
    }
    .v-list-item-finalizado::after{
        width: 6px;
        content: "";
        display: inline-block;
        height: 54px;
        margin-right: -16px;
        margin-left: 16px;
    }
    .finalizado-regular::after{
        background-color: #4CAF50;
    }
    .finalizado-irregular::after{
        background-color: #f31b1b;
    }
</style>